import React from 'react'
import { Link, PageProps } from 'gatsby'
import { ApolloError } from '@apollo/client'
import { styled, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import logoVector from 'resources/logoGrayscaleVector.svg'
import renderError from 'utils/renderError'
import useSubscribeToNewsletter from 'hooks/useSubscribeToNewsletter'

const Background = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'fixed',
  zIndex: -1,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('xs')]: {
    top: 100,
  },
}))

const BackgroundImage = styled('img')(({ theme }: { theme: Theme }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  opacity: 0.1,
  [theme.breakpoints.down('xs')]: {
    opacity: 0.1,
    width: '100%',
    maxHeight: 100,
  },
}))

const SubscribeButton = styled(Button)({
  marginTop: 10,
})

function SubscribeForm({
  onSubmit,
  pending,
  success,
  error,
}: {
  onSubmit: (email: string) => (e: React.SyntheticEvent) => Promise<void>
  pending: boolean
  success: boolean
  error: ApolloError | null | undefined
}) {
  const [email, setEmail] = React.useState('')

  return (
    <form onSubmit={onSubmit(email)}>
      {!success && (
        <>
          <Input
            type="email"
            name="email"
            label="Email address"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            fullWidth
            required
          />
          <SubscribeButton type="submit" disabled={pending} variant="outlined">
            Subscribe Me!
          </SubscribeButton>
        </>
      )}
      {!error && success && (
        <Typography style={{ color: '#43b05d' }}>
          Thank you for subscribing!
        </Typography>
      )}
      {error && !success && renderError(error)}
    </form>
  )
}

function AboutPage({ location }: PageProps) {
  const { subscribe, loading, called, data, error } = useSubscribeToNewsletter()

  const onSubmit = React.useCallback((email: string) => {
    return async (e: React.SyntheticEvent): Promise<void> => {
      e.preventDefault()
      await subscribe({
        variables: {
          email,
          pageUrl: location.href,
          type: 'about',
        },
      })
    }
  }, [])

  return (
    <Page
      title="About"
      subtitle="Subscribe to the newsletter to start receiving latest JavaScript
    articles, straight to your inbox!"
      variant="post"
    >
      <Background>
        <BackgroundImage src={logoVector} alt="" />
      </Background>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 25,
        }}
      >
        <SubscribeForm
          onSubmit={onSubmit}
          pending={loading}
          success={!!data}
          error={error}
        />
      </div>
      <Typography gutterBottom paragraph>
        <em>jsmanifest.com</em> aims to support the web development community by
        promoting high quality educational content about the JavaScript language
        and its technologies.
      </Typography>
      <Typography>
        If you have any questions or concerns please visit the{' '}
        <Link to="/contact">contact page</Link>.
      </Typography>
    </Page>
  )
}

export default AboutPage
